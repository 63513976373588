import React from "react";
import { PageProps, graphql } from "gatsby";

import { BasicHero, Blocks, ErrorMsg, Header, SEO } from "@shared";
import { Query } from "@graphql-types";
import RegionCtaList from "@components/regionCtaList";
import { PageWidth, Section } from "@util/standard";

interface Props extends PageProps {
  data: Query;
}

export default function EventInnerTemplate({ data: { sanityEvent, sanityHeader } }: Props) {
  if (sanityEvent == null)
    return <ErrorMsg data={sanityEvent} msg="Error fetching data for page" />;

  const { slug, seo, hero, eventVillages, blocks } = sanityEvent;

  return (
    <div>
      <SEO seoData={seo} slug={slug?.current as string} />
      <Header data={sanityHeader} />
      {hero && (
        <BasicHero heading={hero.title} blockContent={hero.blockContent} ctas={hero.links} />
      )}
      <Section>
        <PageWidth>
          <Blocks data={blocks} sectionMargins="0px 0" />
        </PageWidth>
      </Section>
      {eventVillages && <RegionCtaList data={eventVillages} />}
    </div>
  );
}

export const query = graphql`
  query eventsInnerQuery($slug: String) {
    sanityHeader {
      ...sanityHeader
    }
    sanityEvent(slug: { current: { eq: $slug } }) {
      _id
      _type
      slug {
        current
      }
      title
      hero {
        ...sanityHero
      }
      blocks {
        ...sanityNewsBlocks
      }
      eventVillages {
        _id
        _key
        title
        slug {
          current
        }
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
