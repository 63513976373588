import React from "react";

import { Maybe, SanityVillage } from "@graphql-types";
import { Container, Section } from "@util/standard";
import { Link } from "@global";
import styled from "styled-components";
import { colors, fontSizes, fontWeights, mediaQuery } from "@util/constants";

interface Props {
  data: Maybe<SanityVillage>[] | undefined;
}

function RegionCtaList({ data }: Props) {
  if (data == null) return null;

  const villageRegions = data
    .map(village => {
      if (village == null) return null;
      return village.region;
    })
    .filter(village => village !== null);

  const sortedRegions = villageRegions.sort((a, b) => {
    if (a?.title == null || b?.title == null) return 0;
    var titleA = a.title.toLowerCase();
    var titleB = b.title.toLowerCase();
    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  });

  return (
    <Section>
      <Container flexDirection="column" width="80%" margin="auto">
        {sortedRegions.map(region => {
          if (region == null) return null;
          return (
            <RegionWrapper key={region._key}>
              <p className="region-title">{region?.title}</p>
              <Link
                className="button-base"
                linkText="View villages in region"
                internalLink={region}
              />
            </RegionWrapper>
          );
        })}
      </Container>
    </Section>
  );
}

export default RegionCtaList;

const RegionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-top: 1px solid ${colors.navy};

  .region-title {
    font-weight: ${fontWeights.bold};
    font-size: ${fontSizes.h2.default}px;
    margin: auto 0;
  }

  &:last-child {
    border-bottom: 1px solid ${colors.navy};
  }
  ${mediaQuery.mobileDown} {
    flex-direction: column;
    padding: 30px 0;
    .region-title {
      margin-bottom: 10px;
    }
  }
`;
